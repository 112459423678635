<template>
  <v-layout justify-center>
    <v-flex
      xs12
      md8
      lg6
    >
      <v-card>
        <v-card-title class="primary white--text">
          <div>
            <div class="headline text-xs-left">
              Eligibility
            </div>
            <span>
              Please fix the following items in order to book reservations.
            </span>
          </div>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <v-flex xs12>
            <v-list>
              <v-list-tile
                v-if="!eligibility.insured"
              >
                <v-list-tile-action>
                  <v-icon
                    :color="eligibility.insured ? 'success' : 'error'"
                  >
                    {{ eligibility.insured ? 'fa-check' : 'fa-ban' }}
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-content class="body-2">
                  Insurance Status - {{ insuranceText }}
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile
                v-if="!eligibility.track_agreement"
              >
                <v-list-tile-action>
                  <v-icon
                    :color="eligibility.track_agreement ? 'success' : 'error'"
                  >
                    {{ eligibility.track_agreement ? 'fa-check' : 'fa-ban' }}
                  </v-icon>
                </v-list-tile-action>
                <v-list-tile-content class="body-2">
                  Track Agreement - {{ trackAgreementText }}
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
          <v-divider class="mb-1" />
          {{ insurancePolicy }}
        </v-card-text>
        <v-card-text class="text-xs-left">
          <h5 class="headline">
            Liability Insurance
          </h5>
          <v-divider class="mb-1" />
          <p
            v-for="(reason, ind) in liabilityReasons"
            :key="ind"
          >
            <v-icon small>
              fa-circle
            </v-icon>
            {{ reason }}
          </p>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import { parse } from 'date-fns'
export default {
  data () {
    return {
      insurancePolicy: `
        Please see the Track Agreement for full details on insurance requirements.
        Questions about this requirement should be directed to Kasie Meszaros (mcity@umich.edu).
        For a copy of the current Mcity Track Agreement, see: https://mcity.umich.edu/book/.
        Please submit proof of insurance to Kasie Meszaros (mcity@umich.edu).
      `,
      liabilityReasons: [
        'Statutory Workers\' Compensation Insurance for statutory limits or a State certificate of self-insurance and Employer\'s Liability insurance with limits of not less than $1,000,000 per accident basis or occurrence.',
        'Occurrence type commercial general liability insurance, including blanket contractual coverage, for bodily injury including death, personal injury and property damage with limits of not less than $5 million combined single limit per occurrence and general aggregate.',
        'Business automobile liability insurance covering all owned, non-owned and hired vehicles, with limits of not less than $5 million combined single limit per accident basis or occurrence.'
      ]
    }
  },
  methods: {
    // Returns true if param date has not already passed
    checkIfExpired (date) {
      const now = new Date()
      const then = parse(date)
      return then > now
    }
  },
  computed: {
    ...mapState('reservation', {
      eligibility: state => state.eligibility
    }),
    isInsuranceValid () {
      return this.eligibility.insurance_expiry
    },
    isTrackAgreementValid () {
      return this.eligibility.track_agreement_date
    },
    insuranceText () {
      const insuranceExpiry = this.eligibility.insurance_expiry
      if (this.isInsuranceValid) {
        return this.checkIfExpired(insuranceExpiry)
          ? `Expires on ${insuranceExpiry}`
          : `Expired on ${insuranceExpiry}`
      } else {
        return 'Unavailable'
      }
    },
    trackAgreementText () {
      const trackAgreementExpiry = this.eligibility.track_agreement_date
      if (this.isTrackAgreementValid) {
        return this.checkIfExpired(trackAgreementExpiry)
          ? `Expires on ${trackAgreementExpiry}`
          : `Expired on ${trackAgreementExpiry}`
      } else {
        return 'Unavailable'
      }
    }
  }
}
</script>

<style>

</style>
